import logo from './logo.png';
import slogan from './slogan.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
        <div>
        Minute papillon ! Un peu de patience ...
        </div>
        <div>
        🛠️ Nous travaillons sur notre site 🔥
        </div>
        <img src={slogan} className="App-slogan" alt=" slogan" />
      </header>
    </div>
  );
}

export default App;
